import { ChangeDetectorRef, Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable, tap, takeUntil, Subject, of, filter, timer } from 'rxjs';
import { ProductDetailsService } from '../../services/product-details-service';
import { Options } from '../../models/product-info';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { isNotNullable, isNotUndefined } from '@spartacus/core';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrl: './add-to-cart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddToCartComponent implements OnInit, OnDestroy {
  userRoles: string[];
  roleFlag: boolean;
  productDetails$: Observable<any>;
  productOption$: Observable<Options>;
  baseProductCode: string;
  variantProductCode: string;
  quantityValue: number;
  minQuantity: number;
  maxQuantity: number;
  maxQtyLimit: number;
  step: number;
  isMaxLmtReached: Observable<boolean>;
  isAddedToCart: boolean;
  showSpinner: number = 0;
  unsubscribe$ = new Subject<void>();

  constructor(
    private productsService: ProductDetailsService,
    private storageService: StorageService,
    private globalService: GlobalService,
    private changeRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.roleCheck(this.storageService.userRoles);
    this.productDetails$ = this.productsService.getProduct().pipe(
      tap((data) => this.baseProductCode = data?.code)
    );

    this.productOption$ = this.productsService.variant$.pipe(
      tap((data) => {
        this.variantProductCode = data?.code;
        if (data && Object.keys(data)?.length > 0) {
          let availbleQty = this.getAvailableQty();
          this.step = data?.interval > 0 ? data?.interval : 1;
          this.minQuantity = this.globalService.fetchProductQuantityValue(data, availbleQty);
          this.maxQtyLimit = data?.maxQuantity ? data.maxQuantity : 1000;
          this.checkMaxQty();
          this.quantityValue = this.globalService.fetchProductQuantityValue(data, availbleQty);
          this.isMaxLmtReached = of(availbleQty >= this.maxQtyLimit);
        }
      })
    );

    this.handleSubscriptions();
  }

  handleSubscriptions() {
    // on Add To Cart
    this.globalService.carts$
      .pipe(
        filter(isNotNullable),
        filter(isNotUndefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        let availbleQty = this.getAvailableQty();
        this.isMaxLmtReached = of(availbleQty >= this.maxQtyLimit);
        this.quantityValue = this.step;
        this.minQuantity = this.step;
        this.changeRef.markForCheck();
      });
  }

  roleCheck(roles: string[] | string) {
    if (roles?.length > 0) {
      this.roleFlag = (typeof roles === 'string')
        ? JSON.parse(roles).includes('Requester')
        : roles.includes('Requester');
    }
  }

  getAvailableQty(): number {
    let cardDetails = this.storageService.activeCart;
    let productDetails = cardDetails?.entries?.filter(
      (item) => item.product.baseProduct === this.baseProductCode && item.product.code === this.variantProductCode
    );
    let quantity = (productDetails?.at(0)?.quantity)
      ? productDetails?.at(0)?.quantity
      : 0;
    return quantity;
  }

  checkMaxQty() {
    let availbleQty = this.getAvailableQty();
    this.maxQuantity = availbleQty ? (this.maxQtyLimit - availbleQty) : this.maxQtyLimit;
  }

  verifyQty() {
    let availbleQty = this.getAvailableQty();
    if ((this.quantityValue === 0 || this.quantityValue < this.minQuantity) && availbleQty === 0) {
      this.quantityValue = this.minQuantity;
    }
    if (this.quantityValue > (this.maxQtyLimit - availbleQty)) {
      this.quantityValue = availbleQty ? (this.maxQtyLimit - availbleQty) : this.maxQtyLimit;
    } else {
      this.quantityValue = this.checkQtyRange(this.quantityValue, this.step);
    }
  }

  checkQtyRange(qty, step): number {
    let updatedQty;
    if (qty > this.maxQuantity) {
      updatedQty = this.maxQuantity;
    } else if (qty <= step) {
      updatedQty = step;
    } else if (qty % step != 0) {
      updatedQty = Math.round(qty / step) * step;
    } else {
      updatedQty = qty;
    }
    return updatedQty;
  }

  onAddToCart(codeVal: string) {
    this.isAddedToCart = true;
    let paramsObject = {
      code: codeVal,
      quantity: this.quantityValue,
    };
    let requestObject = {
      product: {
        code: codeVal,
      },
      quantity: this.quantityValue,
    };
    let cartId = this.storageService.cartId;
    this.globalService.loadingSubject.next(true);
    this.showSpinner = 1;
    this.globalService.addToCart(cartId, paramsObject, requestObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          this.changeStatus();
          this.globalService.getCartsListByLegalEntity();
          this.globalService.carts$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
              let availbleQty = this.getAvailableQty();
              this.isMaxLmtReached = of(availbleQty >= this.maxQtyLimit);
              this.quantityValue = this.step;
              this.minQuantity = this.step;
              this.changeRef.markForCheck();
            });
        },
        error: (error) => {
          this.showSpinner = 0;
          this.globalService.loadingSubject.next(false)
        },
      });
  }

  changeStatus() {
    this.showSpinner = 2;
    this.changeRef.markForCheck();
    timer(1000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.showSpinner = 0;
        this.changeRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
